module.exports = {
	jsHeadInjects: ["webtrekk.js"],
	showCookieMsg: true,
	title: "SMART HOME by Hornbach",
	supportedCountries: ["DE", "AT", "SE", "NL", "CH", "SK", "CZ", "LU"], // don't sort alphabetically
	defaultCountry: "DE",
	supportedCurrencies: ["EUR", "CZK", "RON", "SEK", "CHF"], // don't sort alphabetically
	defaultCurrency: "EUR",
	defaultBbox: [[41, -26], [70.526, 35.2551]],
	showExplore: true,
	serverOptions: [
		{
			label: "DEV",
			id: "dev",
			channel: "hornbach",
			docsVersion: "develop",
			accessCookieConfig: {
				name: "glient2idhornbach",
				path: "/glient2/hornbach",
				domain: ".dev.smarthomebyhornbach.com",
			},
			refreshCookieConfig: {
				name: "refreshhornbach",
				path: "/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/hornbach",
				domain: ".dev.smarthomebyhornbach.com",
			},
			glientWsUrl: "wss://api-client.dev.smarthomebyhornbach.com/glient2/hornbach",
			redirectUrl: "https://dev.smarthomebyhornbach.com/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/hornbach/redirect",
			revokeUrl: "https://dev.smarthomebyhornbach.com/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/hornbach/logout",
			invalidateTokenUrl: "https://dev.smarthomebyhornbach.com/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/hornbach",
			// editAccountUrl: "",
			// migrationWizardUrl: "",
			geofenceEventUrl: "https://api-phone.dev.smarthomebyhornbach.com/api/device/hornbach/phone/",
			widgetSetModeUrl: "https://api-client.dev.smarthomebyhornbach.com/api/public/hornbach/glient/",
		},
		{
			label: "ALPHA",
			id: "alpha",
			channel: "hornbach",
			docsVersion: "develop",
			accessCookieConfig: {
				name: "glient2idhornbach",
				path: "/glient2/hornbach",
				domain: ".dev.faulpelz.io",
			},
			refreshCookieConfig: {
				name: "refreshhornbach",
				path: "/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/hornbach",
				domain: ".dev.faulpelz.io",
			},
			glientWsUrl: "wss://api-client.dev.faulpelz.io/glient2/hornbach",
			redirectUrl: "https://dev.faulpelz.io/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/hornbach/redirect",
			revokeUrl: "https://dev.faulpelz.io/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/hornbach/logout",
			invalidateTokenUrl: "https://dev.faulpelz.io/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/hornbach",
			// editAccountUrl: "",
			// migrationWizardUrl: "",
			geofenceEventUrl: "https://api-phone.dev.faulpelz.io/api/device/hornbach/phone/",
			widgetSetModeUrl: "https://api-client.dev.faulpelz.io/api/public/hornbach/glient/",
		},
		{
			label: "SHHB",
			id: "prod",
			channel: "hornbach",
			docsVersion: "public",
			accessCookieConfig: {
				name: "glient2idhornbach",
				path: "/glient2/hornbach",
				domain: ".prod.smarthomebyhornbach.com",
			},
			refreshCookieConfig: {
				name: "refreshhornbach",
				path: "/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/hornbach",
				domain: ".prod.smarthomebyhornbach.com",
			},
			glientWsUrl: "wss://api-client.prod.smarthomebyhornbach.com/glient2/hornbach",
			redirectUrl: "https://prod.smarthomebyhornbach.com/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/hornbach/redirect",
			revokeUrl: "https://prod.smarthomebyhornbach.com/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/hornbach/logout",
			invalidateTokenUrl: "https://prod.smarthomebyhornbach.com/api/oauth/c0b6b885586709feafbaf25801bc93a2/redirect/hornbach",
			// editAccountUrl: "",
			// migrationWizardUrl: "",
			geofenceEventUrl: "https://api-phone.prod.smarthomebyhornbach.com/api/device/hornbach/phone/",
			widgetSetModeUrl: "https://api-client.prod.smarthomebyhornbach.com/api/public/hornbach/glient/",
		},
	],
	devAppId: {
		android: "com.roc_connect.rocapp.smarthome.hornbach",
		ios: "com.roc-connect.app.hornbach",
	},
	storeId: {
		android: "de.hornbach.app.smarthome",
		ios: 1336041151,
	},
};
